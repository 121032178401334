.ActionPlansList {
    box-sizing: border-box;
    padding: 0 20px
}

.ActionPlansList .ActionPlansListHeading {
    color: white;
    font-family: 'Montserrat';
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 10px;
}

.ActionPlansList .Tips {
    margin-bottom: 30px;
}

.ActionPlansList .NoActionPlans {
    color: #eee;
    margin: auto;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    height: 100vh;
    position: absolute;    
}

.ActionPlansList .NoActionPlans h1 {
    color: white;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 15px;
}

.ActionPlansList .NoActionPlans small {
    color: #897DBB;
    font-size: 16px;
}
