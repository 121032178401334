.Upgrade {
    color: #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;
    z-index: 3;
    width: 100%;
}

.Upgrade h1 {
    color: #eee;
    margin-bottom: 0px;
}

.Upgrade p {
    font-size: 16px;
    margin: 15px 0 30px 0;
}

.Upgrade .UpgradeNow {
    background: linear-gradient(90deg, #897DBB 0%, #76CABC 100%);
    border: none;
    border-bottom: 3px solid #897DBB;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: bold;
    margin: 0 10px;
    padding: 10px 15px;
    text-decoration: none;
}

.Upgrade .UpgradeNow:hover {
    background: #897DBB;
}

.Upgrade .SignOut {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-weight: bold;
}

.Upgrade .SignOut svg {
    fill: white;
    width: 25px;
    position: absolute;
    top: 30px;
    right: 30px;
}

.Upgrade .SignOut:hover {
    color: #eee;
}

.UpgradeDots {
    background: #1f1f1f;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
}

.UpgradeDots .DotBG {
    position: absolute;
    border-radius: 50%;
    z-index: 0;
}