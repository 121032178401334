.NewApplication {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    user-select: none;
}

.NewApplication .CloseButton {
    background: none;
    border: none;
    font-size: 20px;
    color: #eee;
    position: absolute;
    top: 0;
    right: 0;
}

.NewApplication h1 {
    color: white;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 15px;
}

.NewApplication small {
    color: #897DBB;
    font-size: 16px;
}

.NewApplication .Prompt {
    top: 0;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    height: 100vh;
    position: absolute;
}

.NewApplication .Prompt .Heading {
    text-align: center;
    margin-bottom: 50px;
}

.NewApplication .Prompt .RepositoryPrompt {
    position: relative;
}

.NewApplication .Prompt .RepositoryPrompt .PromptInput {
    position: relative;
}

.NewApplication .Prompt .RepositoryPrompt input {
    border: none;
    border-bottom: 1px solid #222;
    border-radius: 5px 5px 0 0;
    background: #1a1a1a;
    color: #eee;
    display: block;
    font-family: 'Montserrat';
    font-size: 15.5px;
    font-weight: 400;
    outline: none;
    user-select: none;
    padding: 15.5px 50px;
    width: 600px;
}

.NewApplication .Prompt .RepositoryPrompt svg {
    fill: #eee;
    width: 20px;
    left: 15px;
    top: 15px;
    position: absolute;
}

.NewApplication .Prompt .RepositoryPrompt .RepositoryURL input {
    border-radius: 5px 5px 0 0;
    background: #1a1a1a;
    color: #eee;
    display: block;
    font-family: 'Montserrat';
    font-size: 15.5px;
    font-weight: 400;
    outline: none;
    user-select: none;
    padding: 15.5px 50px;
    width: 600px;
}

.NewApplication .Prompt .RepositoryPrompt .RepositoryPath {
    position: relative;
}

.NewApplication .Prompt .PromptButton {
    background: linear-gradient(90deg, #897DBB 0%, #76CABC 100%);
    border: none;
    border-radius: 0 0 5px 5px;
    color: white;
    cursor: pointer;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 800;
    padding: 9px 0;
    width: 700px;
}

.NewApplication .Prompt .PromptButton:hover {
    background:#897DBB;
}

.NewApplication .Prompt .PublicKeyPrompt {
    position: relative;
}

.NewApplication .Prompt .PublicKeyPrompt .GoBack {
    color: #897DBB;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 10px;
}

.NewApplication .Prompt .PublicKeyPrompt .GoBack:hover {
    color: #eee;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 10px;
}

.NewApplication .Prompt .PublicKeyPrompt textarea {
    border: none;
    border-radius: 5px 5px 0 0;
    background: #1a1a1a;
    color: #eee;
    display: block;
    font-size: 15.5px;
    font-weight: 400;
    height: 300px;
    outline: none;
    user-select: none;
    padding: 16.5px 50px;
    width: 600px;
}

.NewApplication .Prompt .PublicKeyPrompt svg {
    fill: #eee;
    width: 30px;
    left: 10px;
    top: 43px;
    position: absolute;
}

.NewApplication .ApplicationGeneratingLoader {
    color: white;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    position: absolute;
    top: 0;
}

.NewApplication .ApplicationGeneratingLoader .ApplicationGeneratingLoaderStatus {
    z-index: 4;
}

.NewApplication .ApplicationGeneratingLoader .ApplicationGeneratingLoaderOverlay {
    background: #222;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    opacity: .9;
    width: 100%;
    z-index: 3;
}