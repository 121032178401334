.Login {
    background: #1f1f1f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.Login .LoginForm {
    position: relative;
    z-index: 1;
}

.Login .DotBG {
    position: absolute;
    border-radius: 50%;
    z-index: 0;
}