.StacksList .StacksListHeading {
    color: white;
    font-family: 'Montserrat';
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 10px;
    padding: 0 20px;
}

.StacksList .Tips {
    padding: 0 20px 10px 20px;
}

.StacksList .StacksListItem {
    position: relative;
    margin: 20px;
}

.StacksList .StacksListItem input {
    background: #2a2a2a;
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
    display: inline-block;
    font-family: 'montserrat';
    font-size: 14px;
    height: 45px;
    color: white;
    padding: 0 20px 0 50px;
    outline: none;
    user-select: none;
    width: 100%;
}

.StacksList .StacksListItem label {
    color: #aaa;
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
}

.StacksList .StacksListItem svg.ShowItemValue {
    cursor: pointer;
    fill: #76CABC;
    position: absolute;
    top: 52.5px;
    left: 20px;
    width: 15px;
}

.StacksList .StacksListItem svg.ShowItemValue:hover {
    cursor: pointer;
    fill: #ccc;
}

.StacksList .StacksListItem svg.LockIcon {
    cursor: pointer;
    fill: #aaa;
    position: absolute;
    top: 52.5px;
    left: 20px;
    width: 15px;
}

.StacksList .NoRequiredInputs {
    background: #1b1b1b;
    border-bottom: 1px solid #171717;
    border-radius: 5px;
    color: #aaa;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    margin: 20px;
    padding: 15px;
}
