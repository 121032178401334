.Tips .TipDot {
    background: #555;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    height: 10px;
    margin-right: 7px;
    width: 10px;
}

.Tips .TipDot:hover {
    background: #777;
}

.Tips .TipDot[data-selected="true"] {
    background: #897DBB;
}

.Tips .TipDot[data-selected="true"]:hover {
    background: #897DBB;
}

.Tips .TipText {
    color: #897DBB;
    font-size: 16px;
    margin: 0 0 7px 0;
}

.Tips .TipIcon {
    fill: #ccc;
    margin-right: 5px;
    position: relative;
    top: 1px;
    width: 15px;
}
