@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --container-max-width: 1440px;
  --container-min-width: 960px;
  --header-height: 70px;
  --option-height: 45px;
  --option-padding-top: 20px;
  --option-vertical-padding: 10px;
}