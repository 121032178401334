.ActionPlansActionsList {
    margin-left: 45px;
}

.ActionPlansActionsList .Action {
    background: #2a2a2a;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    position: relative;
    margin-top: 20px;
}

.ActionPlansActionsList .Action:hover {
    background: #2f2f2f;
}

.ActionPlansActionsList .ExcludeButton {
    background: linear-gradient(90deg, #897DBB 0%, #76CABC 100%);
    border: 0;
    border-radius: 5px;
    font-family: 'Montserrat';
    font-weight: 500;
    height: 45px;
    margin-right: 20px;
    padding: 0 15px;
}

.ActionPlansActionsList .ExcludeButton:hover {
    background: #897DBB;
    cursor: pointer;
}

.ActionPlansActionsList .ExcludeButton svg {
    fill: #eee;
    width: 15px;
    position: relative;
    top: 1px;
}

.ActionPlansActionsList .IncludeButton {
    background: #333;
    border: 0;
    border-radius: 5px;
    color: #aaa;
    font-family: 'Montserrat';
    font-weight: 500;
    height: 45px;
    margin-right: 20px;
    padding: 0 15px;
}

.ActionPlansActionsList .IncludeButton:hover {
    background: #3a3a3a;
    cursor: pointer;
}

.ActionPlansActionsList .IncludeButton svg {
    fill: #777;
    width: 15px;
    position: relative;
    top: 1px;
}

.ActionPlansActionsList .ActionDescription {
    background: #252525;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    margin: 0;
    padding: 20px;
}

.ActionPlansActionsList .NoActionsMatched {
    background: #1b1b1b;
    border-bottom: 1px solid #171717;
    border-radius: 5px;
    color: #aaa;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    margin-top: 20px;
    padding: 15px;
}