.Header {
    background: #1f1f1f;
    height: var(--header-height);
    position: relative;
    z-index: 3;
    width: 100%;
}

.HeaderBrand {
    cursor: pointer;
    height: 20px;
    position: relative;
    top: 25px;
    left: 20px;
}

.Header .User {
    float: right;
    margin-right: 20px;
}

.Header .User svg {
    cursor: pointer;
    fill: #eee;
    margin-left: 10px;
    position: relative;
    top: 20px;
    width: 30px;
}

.Header .User svg:hover {
    fill: #897DBB;
}

.Header .User .UserOptions {
    background: #3a3a3a;
    border-radius: 5px;
    color: white;
    padding: 20px;
    position: absolute;
    top: 60px;
    right: 0;
}

.Header .User .UserOptionsCaret {
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #3a3a3a;
    position: absolute;
    top: -6px;
    right: 25px;
}

.Header .User .UserOptions p {
    font-size: 14px;
    margin: 0;
    padding: 0;
}

.Header .User .UserOptions button.SignOut {
    margin-top: 20px;
    width: 100%;
    background: linear-gradient(90deg, #897DBB 0%, #76CABC 100%);
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
    padding: 9px 0;
}

.Header .User .UserOptions button.SignOut:hover {
    background: #897DBB;
}
