.Root {
    background: #1f1f1f;
}

.Root .TrialPeriod {
    box-sizing: border-box;
    color: #ccc;
    font-family: 'Montserrat';
    font-weight: 500;
    padding: 10px;
    position: absolute;
    text-align: center;
    left: 50%;
    top: 7.5px;
    transform: translateX(-50%);
    z-index: 4;
}

.Root .TrialPeriod .TrialPeriodDays {
    color: white;
}

.Root .TrialPeriod .UpgradeNow {
    background: linear-gradient(90deg, #897DBB 0%, #76CABC 100%);
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    padding: 10px 15px;
    text-decoration: none;
}

.Root .TrialPeriod .UpgradeNow:hover {
    background: #897DBB;
}

.ContentArea {
    height: calc(100vh - var(--header-height) - var(--option-height) - var(--option-padding-top) - 20px);
    overflow-y: scroll;
    padding-top: 20px;
}

.Container {
    max-width: var(--container-max-width);
    min-width: var(--container-min-width);
    margin: auto;
}