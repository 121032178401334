.LoginForm {
    background: #2a2a2a;
    border-bottom: 3px solid #1a1a1a;
    border-radius: 5px;
    box-sizing: border-box;
    width: 400px;
}

.LoginForm .Brand {
    margin: 30px 0;
    text-align: center;
}

.LoginForm .Brand img {
    width: 200px;
}

.LoginForm .Options {
    margin-bottom: 40px;
    width: 100%;
}

.LoginForm .Options button {
    background: #262626;
    border: none;
    border-top: 1px solid #222;
    border-bottom: 1px solid #222;
    color: #777;
    cursor: pointer;
    font-family: 'Montserrat';
    font-size: 15px;
    font-weight: 500;
    outline: none;
    padding: 20px;
    user-select: none;
    width: 50%;
}

.LoginForm .Options button:first-of-type {
    border-bottom-right-radius: 5px;
}

.LoginForm .Options button:last-of-type {
    border-bottom-left-radius: 5px;
}

.LoginForm .Options button[data-selected="true"] {
    background: #2a2a2a;
    border-bottom: none;
    color: #ccc;
}