.ActionPlansContextOptionNewApplication {
    background: #444;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-family: 'Montserrat';
    font-weight: 500;
    padding: 13.5px 20px;
}

.ActionPlansContextOptionNewApplication:hover {
    background: #897DBB;
}