.ResourcesLoadingOverlay  {
    background-color: #1f1f1f;
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    height: 100vh;
    justify-content: center;
    flex-direction: column;
    display: flex;
    opacity: .9;
    width: 100%;
    z-index: 5;
}