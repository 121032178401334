.ActionPlansListItem {
    margin-bottom: 20px;
}

.ActionPlansListItem .ListItemBody {
    background: #2a2a2a;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    position: relative;
}

.ActionPlansListItem .ListItemBody .SyncButton {
    background: linear-gradient(90deg, #897DBB 0%, #76CABC 100%);
    border: 0;
    border-radius: 5px;
    color: white;
    font-family: 'Montserrat';
    font-weight: 500;
    height: 45px;
    margin-right: 20px;
    padding: 0 15px;
}

.ActionPlansListItem .ListItemBody .SyncButton:hover {
    background: #897DBB;
    cursor: pointer;
}

.ActionPlansListItem .ListItemBody .SyncButton svg {
    fill: #eee;
    width: 15px;
    position: relative;
    top: 1px;
}

.ActionPlansListItem .DeleteButton {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 50px;
    top: 14.5px;
}

.ActionPlansListItem .DeleteButton svg {
    fill: #aaa;
    width: 15px;
}

.ActionPlansListItem .DeleteButton:hover svg {
    fill: #eee;
}

.ActionPlansListItem .ListItemBody .DownCaretIcon {
    fill: #777;
    width: 10px;
    position: absolute;
    right: 20px;
    top: 18.5px;
}

.ActionPlansListItem .ListItemBody .RightCaretIcon {
    fill: #777;
    width: 10px;
    position: absolute;
    right: 18.5px;
    top: 18px;
}

.ActionPlansListItem .ListItemBody .InputsAlert svg {
    fill: #f5a742;
    display: inline-block;
    margin-left: 20px;
    position: relative;
    top: 2px;
    width: 15px;
}

.ActionPlansListItem .ListItemBody .InputsAlert .UnresolvedInputs {
    color: #999;
    font-style: italic;
    margin-left: 10px;
}

.ActionPlansListItem .PublicKeyPromptWrapper {
    background: #111;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    left: 0;
    opacity: .9;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
}

.ActionPlansListItem .PublicKeyPrompt {
    position: absolute;
}

.ActionPlansListItem .PublicKeyPrompt .GoBack {
    color: #897DBB;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 10px;
    text-align: left;
}

.ActionPlansListItem .PublicKeyPrompt .GoBack:hover {
    color: #eee;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 10px;
}

.ActionPlansListItem .PublicKeyPrompt textarea {
    border: none;
    border-radius: 5px 5px 0 0;
    background: #1a1a1a;
    color: #eee;
    display: block;
    font-size: 15.5px;
    font-weight: 400;
    height: 300px;
    outline: none;
    user-select: none;
    padding: 16.5px 50px;
    width: 600px;
}

.ActionPlansListItem .PublicKeyPrompt svg {
    fill: #eee;
    width: 30px;
    left: 10px;
    top: 43px;
    position: absolute;
}

.ActionPlansListItem .BasicAuthPromptWrapper {
    background: #111;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    left: 0;
    opacity: .9;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
}


.ActionPlansListItem .BasicAuthPrompt {
    position: relative;
}

.ActionPlansListItem .BasicAuthPrompt .PromptInput {
    position: relative;
}

.ActionPlansListItem .BasicAuthPrompt .GoBack {
    color: #897DBB;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 10px;
    text-align: left;
}

.ActionPlansListItem .BasicAuthPrompt .GoBack:hover {
    color: #eee;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 10px;
}


.ActionPlansListItem .BasicAuthPrompt input {
    border: none;
    border-bottom: 1px solid #222;
    border-radius: 5px 5px 0 0;
    background: #1a1a1a;
    color: #eee;
    display: block;
    font-family: 'Montserrat';
    font-size: 15.5px;
    font-weight: 400;
    outline: none;
    user-select: none;
    padding: 15.5px 50px;
    width: 600px;
}

.ActionPlansListItem .BasicAuthPrompt svg {
    fill: #eee;
    width: 20px;
    left: 15px;
    top: 15px;
    position: absolute;
}

.ActionPlansListItem .PromptButton {
    background: linear-gradient(90deg, #897DBB 0%, #76CABC 100%);
    border: none;
    border-radius: 0 0 5px 5px;
    color: white;
    cursor: pointer;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 800;
    padding: 9px 0;
    width: 700px;
}

.ActionPlansListItem .PromptButton:hover {
    background:#897DBB;
}

.ActionPlansListItem .ActionPlanSyncingLoader {
    color: white;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
}

.ActionPlansListItem .ActionPlanSyncingLoader .ActionPlanSyncingLoaderStatus {
    z-index: 4;
}

.ActionPlansListItem .ActionPlanSyncingLoader .ActionPlanSyncingLoaderOverlay {
    background: #222;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    opacity: .9;
    width: 100%;
    z-index: 3;
}