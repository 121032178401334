.StackSaveButtonContextOption {
    background: #444;
    border: none;
    border-radius: 5px;
    color: #626262;
    font-family: 'Montserrat';
    font-weight: 500;
    padding: 13.5px 20px;
}

.StackSaveButtonContextOption[data-enabled="true"] {
    background: #897DBB;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-family: 'Montserrat';
    font-weight: 600;
    padding: 13.5px 20px;
}

.StackSaveButtonContextOption[data-enabled="true"]:hover {
    background: #7B70A8;
}

.StackContextSelectContextOption {
    background: #2a2a2a;
    border: none;
    border-radius: 5px;
    color: #eee;
    cursor: pointer;
    font-family: 'Montserrat';
    font-weight: 500;
    margin-right: 10px;
    padding: 13.5px 20px;
}


.StackContextSelectOverridesOption {
    height: 45px;
    background: #2a2a2a;
    border: none;
    border-radius: 5px;
    color: #eee;
    cursor: pointer;
    font-family: 'Montserrat';
    font-weight: 500;
    margin-right: 10px;
    padding: 13.5px 20px;
    width: auto;
}

.StackNewContextInputContextOption {
    background: #2a2a2a;
    border: none;
    border-radius: 5px;
    color: #eee;
    font-family: 'Montserrat';
    font-weight: 300;
    margin-right: 10px;
    padding: 13.5px 20px;
    user-select: none;
    outline: none;
    width: 100px;
}

.StackAgeSecretKeyContextOptionSubmit {
    background: #333;
    border: none;
    border-radius: 0 5px 5px 0;
    color: #626262;
    cursor: pointer;
    font-family: 'Montserrat';
    font-weight: 500;
    margin-right: 10px;
    padding: 0 15px;
    position: relative;
    top: 1px;
    height: 45px;
}

.StackAgeSecretKeyContextOptionSubmit:hover {
    background-color: #444;
}

.StackAgeSecretKeyContextOptionSubmit svg {
    fill: #eee;
    position: relative;
    width: 15px;
}

.StackAgeSecretKeyContextOptionSubmit[data-enabled="true"] {
    background: #897DBB;
}

.StackAgeSecretKeyContextOptionSubmit[data-enabled="true"] svg {
    fill: #eee;
}

.StackAgeSecretKeyContextOption {
    background: #2a2a2a;
    border: none;
    border-radius: 5px 0 0 5px;
    color: #eee;
    font-family: 'Montserrat';
    font-weight: 300;
    padding: 14.5px 20px;
    user-select: none;
    outline: none;
    width: 150px;
}
