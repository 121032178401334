.Option {
    background: #1f1f1f;
    box-shadow: 0 10px 15px #1f1f1f;
    height: var(--option-height);
    padding: var(--option-padding-top) 20px 0 20px;
    position: relative;
    z-index: 2;
}

.Option .Links {
    background: #2a2a2a;
    border-radius: 5px;
    display: inline-block;
    padding: var(--option-vertical-padding) 20px;
    margin-right: 10px;
    position: relative;
    width: auto;
}

.Option .Links .OptionApplicationsIcon {
    fill: #aaa;
    height: 20px;
    position: relative;
    margin-right: 20px;
    top: 2px;
}

.Option .Links .OptionStacksIcon {
    fill: #aaa;
    height: 18px;
    position: relative;
    top: 1px;
}

.Option .Links .OptionLink {
    cursor: pointer;
    display: inline-block;
}

.Option .Links .OptionLink:hover {
    fill: #eee;
}

.Option .Links .OptionLink[data-selected="true"] {
    fill: #897DBB;
}

.Option .Links .OptionLink[data-selected="true"]:hover {
    fill: #897DBB;
}

.Option .ContextOptions {
    position: relative;
    top: -3px;
    display: inline-block;
}