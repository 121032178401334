.LoginFormRegister input {
    background: #262626;
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
    color: #eee;
    display: block;
    font-family: 'Montserrat';
    font-size: 15px;
    margin: 40px 30px;
    padding: 15px;
    outline: none;
    width: calc(100% - 60px);
}

.LoginFormRegister button {
    background: linear-gradient(90deg, #897DBB 0%, #76CABC 100%);
    border: none;
    border-radius: 0 0 5px 5px;
    color: white;
    cursor: pointer;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 800;
    padding: 9px 0;
    margin-top: 15px;
    width: 100%;
}

.LoginFormRegister button:hover {
    background:#897DBB;
}
